<template>
    <a-table  size="small" :columns="columns" :row-key="record => record.name" :data-source="listData" bordered
              :pagination="pagination"
              :loading="loading" @change="handleTableChange">
    </a-table>
</template>
<script>
import { onMounted, reactive, toRefs } from 'vue'
import { promoterDetailPage } from '@/api/BEnd/applet'
export default {
  props: {
    userId: {
      type: String
    }
  },
  setup (props) {
    const state = reactive({
      userId: '',
      loading: false,
      listData: [],
      columns: [
        {
          title: '电话号',
          dataIndex: 'mobile',
          // width: '10%',
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'regTime',
          align: 'center'
          // width: '10%',
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })

    const loadData = () => {
      state.loading = true
      promoterDetailPage({
        id: state.userId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    onMounted(() => {
      if (props.userId) {
        state.userId = props.userId
        loadData()
      }
    })

    return {
      ...toRefs(state),
      loadData,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
