<template>
  <a-table  size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
            :pagination="pagination"
            :loading="loading" @change="handleTableChange">
    <template #vehicles="{ record }">
      <div v-if="record.vehicles!=null&&record.vehicles.length>0">
        <div v-for="item in record.vehicles" :key="item.id">
          <a-row  >
            <a-col :span="12"  v-if="item.brand!==''&&item.model!==''" >
              {{ item.brand }}-{{ item.model }}
            </a-col>
            <a-col :span="7" >
              {{ item.vinNo}}
            </a-col>
            <a-col :span="5" v-if="item.cost!==''&&item.cost!==null" >
              {{ item.cost }}万
            </a-col>
          </a-row>
        </div>
      </div>
    </template>
  </a-table>
</template>
<script>
import { onMounted, reactive, toRefs } from 'vue'
import { userPage } from '@/api/BEnd/order'
export default {
  props: {
    userId: {
      type: String
    }
  },
  setup (props) {
    const state = reactive({
      userId: '',
      loading: false,
      listData: [],
      columns: [
        {
          title: '用户名',
          dataIndex: 'mobile',
          align: 'center',
          width: '8%'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          align: 'center'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          align: 'center'
        },
        {
          title: '订单金额',
          dataIndex: 'orderAmt',
          align: 'center',
          width: '8%'
        },
        {
          title: '是否使用优惠券',
          dataIndex: 'isCoupon.label',
          align: 'center',
          width: '8%'
        },
        {
          title: '优惠券金额',
          dataIndex: 'couponAmt',
          align: 'center',
          width: '8%'
        },
        {
          title: '车辆信息',
          dataIndex: 'vehicles',
          align: 'center',
          width: '20%',
          slots: {
            customRender: 'vehicles'
          }
        },
        {
          title: '预约日期',
          dataIndex: 'transTime',
          align: 'center',
          width: '10%'
        },
        {
          title: '下单日期',
          dataIndex: 'createTime',
          align: 'center',
          width: '10%'
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })

    const loadData = () => {
      state.loading = true
      userPage({
        userId: state.userId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    onMounted(() => {
      if (props.userId) {
        state.userId = props.userId
        loadData()
      }
    })

    return {
      ...toRefs(state),
      loadData,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
